.app-container-wrapper {
  width: 100%;
  max-width: 100vw;

  &.desktop {
    height: auto;
    min-height: calc(100vh - var(--header-height));
    overflow-y: unset;
    margin-top: 65px;
  }

  &.background {
    background-color: var(--color-brand-blue);
    height: 100vh;
  }
}

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 0 20px;


  &.desktop {
    max-width: var(--container-desktop-width);
    padding: var(--container-desktop-padding);
    margin: 0 auto;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--app-container-header-height));
  }
}

.app-container-header-wrapper {
  background-color: var(--color-brand-blue);
  padding: 20px 0;
  position: relative;
  border-bottom-right-radius: 32px;

  &.full-size {
    height: 180px;
  }

  &.mid-size {
    height: 160px;
  }

  .header-logo {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 117px;
    /* Need a specific value to work */
  }

  .app-container-header {
    display: flex;
    align-items: center;
    color: white;
    max-width: var(--container-desktop-width);
    padding: var(--container-desktop-padding);
    margin: 0 auto;

    .title {
      font-size: 30px;
      font-weight: 500;
      color: white;
      margin-left: 10px;
    }
  }
}