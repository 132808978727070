@import "../../variables";

.groupByData {
  &:first-child {
    .groupByData__date {
      margin-top: 0;
    }
  }
  .groupByData__date {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    padding: 0 1rem;
    font-size: 0.9rem;
    font-style: italic;
    color: var(--color-grey-dates);
  }
  .groupByData__card {
    border-radius: 12px;
    background-color: $color-light-blue;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.groupByData__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.2rem 0.8rem;
  .groupByData__company {
    color: $color-blue-dark;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
  }
  .groupByData__date_card {
    font-size: 10px;
    text-align: right;
    color: #77869e;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.attachments {
  a {
    text-decoration: underline;
  }
  font-size: 0.9rem;
  svg {
    margin-right: 0.3rem;
  }
}
