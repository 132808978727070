@import '../../../variables.scss';

.recommendation {
  &__header {
    padding-bottom: 0.5rem;
  }
  &__body {
    padding: 0 1.2rem 1rem;
    .recommendation__info {
      min-width: 140px;
      background-color: $color-light-blue;
      border-radius: 12px;
      padding: 5px 10px;
      &-arrow {
        path {
          &:nth-child(2) {
            fill: $color-blue-dark;
          }
        }
      }
      &--opacity {
        opacity: 0.4;
      }
      &_separator {
        display: flex;
        align-items: center;
        position: relative;
      }
       &-right {
        display: flex;
      }
    }
    .recommendation__types {
      display: flex;
      font-size: 0.8rem;
      .recommendation__type {
        text-transform: capitalize;
        margin-right: 0.5rem;
      }
    }
    .recommendation__prices {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem 0;
      max-width: 350px;
      .recommendation__price {
        font-size: 16px;
        font-weight: 600;
        color: $color-blue-dark;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.5rem;
          font-weight: 500;
          font-size: 14px;
        }
        &-arrow {
          border-radius: 6px;
          height: 22px;
          width: 22px;
          display: inline-block;
          &.arrow-up {
            background-color: $color-light-green;
            path { fill: $color-positive-1 }
          }
          &.arrow-down {
            background-color: $color-red;
            path { fill: $color-negative-1 }
          }
        }
      }
    }
    .recommendation__attachments {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      color: $color-blue-dark;
      font-size: 12px;
      font-weight: 500;
    }
    .action__bullets {
      span {
        font-size: .80rem;
        &.sell,
        &.underweight {
          background-color: var(--color-negative-2);
        }
        &.buy,
        &.accepttheoffer,
        &.accumulate {
          background-color: var(--color-positive-2);
        }
      }
    }
  }
}

.brokers-note .recommendation__info {
  background-color: white;
}
