@import "../../../../variables";

.desktop-price-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-start;

  .price {
    background-color: $color-light-blue;
    margin-right: 20px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 600px;

    &__name {
      flex-basis: 40%;
    }

    &__price {
      flex-basis: 20%;
      font-weight: 500;

      .current {
        white-space: nowrap;
      }
    }

    &__variation {
      flex-basis: 25%;
    }

    &__session {
      flex-basis: 10%;
      order: 4;
    }
  }

  .indices__item {
    background-color: $color-light-blue;
  }
}

.desktop-content-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .tables__container h4 {
    font-size: 20px;
  }

  > div {
    width: 32%;
  }

  .volumeschart,
  .tables__container {
    padding-top: 0;
    margin-top: 0;
  }
}

.desktop-stock-volumes {
  .stockchart,
  .price-cumlulated-updated {
    margin-left: 0;
    margin-right: 0;
  }

  .stockchart__title {
    font-size: 20px;
  }

  .price-cumlulated-updated {
    margin-top: 0;
    padding: 20px;
  }
}

.stock-enlarge-button {
  color: #042c5c;
  background: transparent;
  border: none;
  font-size: 12px;

  &:hover {
    color: grey;
    cursor: pointer;
  }
}

.fullscreen-chart {
  .fullscreen-chart-content {
    .stockchart.volumeschart {
      .stockchart__legend {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
