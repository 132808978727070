@import "../../variables.scss";

.mobile-header {
  position: relative;
  border-bottom: 1px solid var(--color-brand-blue);
  background-color: var(--color-brand-blue);
  padding-top: calc(env(safe-area-inset-top) + 1rem);
  min-height: 120px;
  border-bottom-right-radius: 32px;

  &--notch {
    padding-top: calc(env(safe-area-inset-top) + 0.5rem);
  }

  &.full-size {
    min-height: 290px;
  }

  &.mid-size {
    min-height: 160px;
  }

  .menu-items {
    position: absolute;
    padding: 1rem 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .menu-items__left {
      display: flex;
      .search {
        margin-left: 20px;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          padding-left: 5px;
          font-weight: 500;
          color: $color-white;
        }
      }
    }
  }

  .logo__container {
    text-align: center;
  }

  .user.btn__rm-styles {
    text-align: right;
  }

  .goback.btn__rm-styles {
    text-align: left;
  }
}

.mobile-logo-wrapper {
  position: absolute;
  left: 50%;

  .mobile-logo {
    position: relative;
    left: -50%;
  }
}

.empty-header {
  display: none;
}

.desktop-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem 2rem;
  position: fixed;
  top: 0;
  z-index: 998;

  &__logo {
    width: 30%;

    img {
      max-width: 120px;
      margin: 10px 0;
    }
  }

  &__items {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-item {
      display: flex;
      align-items: center;
      color: $footer-links;
      text-align: center;
      font-size: 1rem;
      margin-right: 30px;
      position: relative;

      svg {
        display: inline-block;
        margin-right: 10px;
      }

      &.active {
        font-weight: 600;

        svg {
          background: $color-blue-dark;
          padding: 2px;
          border-radius: 50%;
        }
      }
    }
  }

  &__user {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0;
    color: var(--color-brand-blue);
    border-left: 1px #d0d0d0 solid;
    margin-top: -81px;
    margin-bottom: -81px;
    height: 63px;

    svg {
      margin-left: 1.8rem;
    }

    &-name {
      margin-left: 10px;
    }
  }

  .arrow-down {
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-black-dark);
    margin-left: 20px;
  }
}

.user-modal {
  position: absolute;
  background-color: white;
  height: 100vh;
  width: 100%;
  z-index: 100000;
  opacity: 0.5;
}

.user-content {
  position: absolute;
  right: 0;
  top: 0;
  background-image: linear-gradient(147deg, #001833 9%, #022d5f 96%);
  width: 277px;
  height: 273px;
  z-index: 100001;
  padding: 22px 39px;

  .user-top {
    display: flex;
    align-items: center;

    .arrow-up {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      margin-left: 10px;
    }

    .name {
      font-size: 16px;
      color: var(--color-white);
      margin-left: 10px;
    }
  }

  .role {
    font-size: 13px;
    font-style: italic;
    color: var(--color-text-blue-light);
    text-align: center;
  }

  hr {
    border: solid 1px #001227;
    margin: 30px 0;
  }

  .email,
  .phone {
    font-size: 13px;
    color: #fcfcfc;
    margin: 10px 0;
  }

  button {
    border: solid 1.5px var(--color-white);
    background: transparent;
    width: 100%;
    height: 40px;
    color: white;
    margin-top: 20px;
  }
}

.red-circle-top-bar {
  background-color: red;
  height: 10px;
  width: 10px;
  position: relative;
  top: -15px;
  border-radius: 5px;
}

.desktop-header__items-item {
  margin-right: 20px;
  svg {
    margin-right: 3px;
  }
  .inline-block {
    white-space: nowrap;
    font-size: 14px;
  }
}
