@import "../../../../variables";

.price {
  display: flex;
  border-radius: 12px;
  box-shadow: 0 0 20px 0 #00000014;
  padding: 16px;
  background-color: white;
  flex-wrap: wrap;

  &__name {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    color: $color-blue-dark;
  }

  &__session {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50px;

    &-time {
      color: #77869e;
      font-size: 10px;
    }
  }

  &__price {
    color: $color-blue-dark;
    font-size: 30px;
    font-weight: 600;
    flex-basis: 50%;
    .currency {
      font-size: 16px;
    }
  }
  &__variation {
    display: flex;
    align-items: center;
    .arrow {
      border-radius: 6px;
      margin-right: 5px;
      &.up {
        background-color: $color-light-green;
        path { fill: $color-positive-1 }
      }
      &.down {
        background-color: $color-red;
        path { fill: $color-negative-1 }
      }
    }
    .quote {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
