@import '../../../../variables';

.consensus-logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -20px;
  img {
    max-width: 200px;
  }
}

.va-text {
  margin-top: 40px;
  font-style: italic;
  margin-bottom: 120px;
}

.consensus-content {
  padding-bottom: 100px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 5px;
    margin-top: 25px;
  }

  &.is-desktop {
    .consensus-table {
      overflow-x: inherit;

      table {
        width: 75%;
      }
    }
  }

  .consensus-table {
    min-width: 100% !important;
    overflow-x: scroll;

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;

      .period {
        padding-right: 10px;
        font-size: 14px;
      }

      .fixed {
        position: sticky;
        left: 0;
        background-color: inherit;
        z-index: 2;
        padding-right: 10px;
      }

      .headerFixed {
        background-color: white;
      }

      thead {
        th {
          font-size: 14px;
          font-weight: bold;
          color: $color-grey-dark;
          padding-bottom: 3px;
          text-transform: uppercase;
          text-align: right;
          min-width: 90px;

          &:first-child {
            min-width: 140px;
          }

          .th-wrapper {
            display: flex;
            align-items: baseline;
            justify-content: center;

            .arrow {
              position: relative;
              top: 1px;
              margin-left: 1px;
            }
          }

          &:first-child {
            text-align: left;
            padding-left: 10px;

            .th-wrapper {
              justify-content: flex-start;
            }
          }

          &:last-child {
            padding-right: 10px;
            padding-left: 5px;
          }

          &.selected {
            font-weight: bold;
            color: $color-blue-dark;
          }
        }
      }

      tbody {
        color: $color-blue-dark;

        tr {
          background-color: $color-light-blue;
          margin-bottom: 10px;
          padding: 10px;
          margin-bottom: 10px;

          &:nth-child(odd) {
            background-color: $color-medium-blue;
          }

          &.highlighted td {
            border-top: 1px solid $color-brand-blue;
            border-bottom: 1px solid $color-brand-blue;
            &:first-child {
              border-left: 1px solid $color-brand-blue;
            }
            &:last-child {
              border-right: 1px solid $color-brand-blue;
            }
          }
        }

        td {
          font-size: 12px;
          font-weight: 600;
          padding: 10px 0;
          text-align: center;

          &:first-child {
            text-align: left;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 5px;
          }

          .me {
            font-weight: bold;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            padding-right: 12px;
            padding-left: 10px;
          }

          &.sorted {
            font-weight: 600;
          }
        }
      }
    }
  }
}
