@import "../../variables.scss";

.flash__content {
  padding-bottom: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: $color-black-light;
  .flash__title {
    font-size: 22px;
    letter-spacing: 0.55px;
  }
  .flash__description {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.12px;
  }
}
