.password__input-container {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 30px;

  ::-webkit-input-placeholder {
    color: white;
    transition: inherit;
  }

  label {
    position: relative;
    span {
      position: absolute;
      bottom: 0;
      margin-bottom: 0 !important;
      padding: 1rem 0.8rem;
      width: 100%;
      cursor: text;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }
  }
}

.password-change__result.result__error {
  font-size: 0.9rem;
  margin-bottom: 3rem;
  color: var(--color-negative-5);
}

.isLoading__login_form {
  margin-bottom: 3rem;
  svg {
    path {
      fill: #fff;
    }
  }
}

.notice__message {
  font-weight: bold;
  text-align: center;
  color: white;
  padding-bottom: 1rem;
  margin-bottom: 30px;
}
