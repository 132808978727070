@import "../../variables.scss";

.app-container__login {
  justify-content: center;
  height: auto;
  min-height: 100vh;
  background-size: contain;

  .success-icon {
    display: flex;
    align-self: center;
  }
}

.login-container {
  overflow: hidden;
  height: auto;
  min-height: 100vh;
  position: relative;
}

#myVideo {
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 0;
  transform: translate(-50%, 0); /* % of current element */
  height: auto;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 100%; /* 100 * 9 / 16 */
  object-fit: cover;
}

.logo-full__container {
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 4rem 2.5rem 2.5rem;
}

.title__container {
  font-size: 26px;
  color: var(--color-text-blue-light);
  padding-bottom: 50px;
}

.login__container {
  width: 100%;

  &.desktop {
    width: 400px;
    margin: 0 auto;
  }
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  &.no-logo {
    padding-top: 7rem;
  }
  .icon-input {
    position: absolute;
    right: 0px;
    top: 19%;
    background-color: transparent;
    outline: none;
    border: none;
    &.icon-input--right {
      right: 40px;
    }
  }
  .login__input-container {
    position: relative;
    margin-bottom: 3rem;
  }
  .login__button-container {
    text-align: center;
    margin-top: 3.125rem;
    border-radius: 6px;
  }
  .login__forgotten {
    color: var(--color-text-blue-light);
    font-style: italic;
    font-size: 0.9rem;
    text-align: right;
  }
}

.login__links {
  margin-top: 1rem;
  text-align: center;
  svg {
    vertical-align: sub;
  }
  a {
    text-decoration: underline;
    color: var(--color-text-blue-light);
    padding-top: 50px;
  }
}

.login__input-container {
  display: flex;
  flex-flow: column-reverse;

  label {
    position: relative;
    span {
      position: absolute;
      bottom: 0;
      margin-bottom: 0 !important;
      padding: 1rem 0.8rem;
      width: 100%;
      cursor: text;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }
  }

  span,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  span {
    color: white;
  }

  input:focus {
    outline: 0;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:not(:placeholder-shown) + span,
  input:focus + span {
    bottom: 100%;
    font-size: 12px;
    color: var(--color-text-blue-light);
    cursor: pointer;
    padding: 0 0.8rem;
  }
}
