@import '../../../../variables.scss';

.flash {
  .flash-card__attachments {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $color-blue-dark;
    margin-top: 1rem;
    a {
      text-decoration: none;
      position: relative;
      top: 1px;
    }
    svg {
      margin-right: 0.2rem;
    }
  }
  
  &--active {
    border: solid 2px $color-blue-dark;
  }
}
