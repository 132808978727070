@import "../../../../variables.scss";

.flash__detail {
  margin-bottom: 50px;
  .flash__content {
    margin-top: 1rem;
    .flash__title {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .flash__attachments {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
      .attachment__file {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: $color-blue-dark;
        display: flex;
        align-items: center;
        svg {
          display: block;
          margin-right: 10px;
        }
        a {
          display: block;
          width: 300px;
          overflow-wrap: break-word;
        }
      }
    }
    .flash__links {
      margin-top: 30px;
      a {
        text-decoration: underline;
      }
    }
  }
}
