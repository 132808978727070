@import '../../../../variables';

.indices {
  padding-left: 1vh;
  padding-right: 1vh;
  padding-bottom: 1vh;
  overflow: auto;
  display: flex;

  &.desktop {
    flex-wrap: nowrap;
  }

  &__item {
    flex: 0 0 auto;		
    width: max-content;
    margin: 10px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 0 20px 0 #00000014;
    font-size: 12px;
    font-weight: 800;
    color: $color-blue-dark;
    padding: 10px;

    &.closed .variation-wrapper {
      margin-left: 5px;
    }

    &:first-child {
      margin-left: 0;
    }

    .desktop & {
      justify-content: flex-start;
      width: auto;
      margin-right: 1rem;
    }

    &-name {
      color: $color-brand-blue;
    }

    .variation-wrapper {
      display: flex;
      align-items: center;
      text-align: left;
      margin-top: 8px;
    }

    .indice-item-arrow {
      border-radius: 6px;
    }

    svg {
      margin-right: 2px;
    }
    
    &.down {
      .indice-item-arrow {
        background: $color-red;
      }
      svg {
        path { fill: $color-negative-1};
      }
      .variation {
        background-color: $color-negative-1;
        color: $color-brand-blue;
      }
    }
    &.up {
      .indice-item-arrow {
        background: $color-light-green;
      }
      svg {
        path { fill: $color-positive-1};
      }
      .variation {
        background-color: $color-positive-1;
        color: $color-brand-blue;
      }
    }
  }
}
