$sm: '640px';
$md: '768px';
$lg: '1024px';
$xl: '1280px';

$header-height: '65px';
$app-container-header-height: '0';
$footer-height: '53px';
$container-desktop-width: '1344px';
$container-desktop-padding: '0 2rem';

$color-negative-1: #f24750;
$color-positive-1: #1bc773;
$color-light-green: #e2f2eb;
$color-red: #f6e6e7;
$color-blue: #bfc7cf;
$color-blue-dark: #042c5c;
$color-brand-blue: #112C5C;
$color-medium-blue: #dfe7f5;
$color-text-blue-light: #bdcee2;
$color-light-blue: #f2f5fb;
$color-brand-light-blue: #1c4a81;
$color-brand-indigo-25: #c2c2db;
$color-yellow: #ffcd00;
$color-gold: #fff2bf;
$color-gold-lighter: #fffae5;
$color-brand-gold-25: #fff2cd;
$color-white: #ffffff;
$color-white-dark: #f3f3f1;
$color-black-dark: #161d26;
$color-black-light: #252525;
$color-grey: #dbdcdf;
$color-grey-dark: #828286;
$color-grey-darker: #c7c3bb;

$desktop-background: #f9f9f8;
$footer-links: $color-blue-dark;
$subheader-bg: #012652;
$subheader-bg-active: #002c61;
$color-grey-dates: #7c7c80;
$color-blue-indices-bg: #001227;
