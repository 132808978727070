.brokers-note {
  position: relative;
  .subheader {
    margin-bottom: 30px;
    top: -50px;
  }

  .brokers__content {
    padding: 1.2rem 0;
    min-height: 100%;
    flex-shrink: 0;
    margin-bottom: var(--header-height);
    &.desktop {
      width: 50%;
      padding: 0;
    }
  }
}
