@import "../../../../variables.scss";

.imported-notes {
  padding-bottom: 26px;
  h4 {
    color: var(--color-black-light);
  }
  &__updated {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: 0.23px;
    text-align: center;
    color: $color-grey-dark;
  }
  &__card {
    background-color: white !important;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-blue-dark;
    text-transform: uppercase;
  }
  .imported-notes__content {
    @media (min-width: $lg) {
      max-width: 832px;
      column-count: 1;
      column-gap: 1.2rem;
    }
  }
  .imported-notes__group {
     &:not(:first-child) {
      margin-top: 20px;
    }
    h5 {
      margin-bottom: 10px;
      margin-top: 0px;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
    @media (min-width: $lg) {
      width: 340px;
      margin-bottom: 0;
      display: inline-block;
      break-inside: avoid-column;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  .recommendation__company {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.12px;
    color: $color-blue-dark;
  }
  .recommendation__header.groupByData__header {
    padding: 0;
  }
  .recommendation__body {
    padding: 0;
  }
}
