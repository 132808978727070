@import '../../../../variables';


.valorisation-logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  top: -20px;
  p {
    margin-bottom: 4px;
    font-size: 12px;
  }
  img {
    max-width: 120px;
  }
}

.va-text {
  margin-top: 40px;
  font-style: italic;
  margin-bottom: 120px;
}

.valorisation {
  h1,
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $color-blue-dark;
    text-align: left;
    @media (max-width: $sm) {
      font-size: 27px;
      padding-bottom: 20px;
    }
  }
  margin-bottom: 6rem;
  .charts {
    display: flex;
    flex-direction: column;
    gap: 6rem;

    &.desktop {
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
  .button-opener {
    color: #042c5c;
    background: #f2f5fb;
    border: 1.9px #042c5c solid;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 30px;
    font-size: 12px;

    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.modal-overlay {
  .peers-filter-title {
    background: #1c4a81;
    position: relative;
    padding: 20px;
    color: white;
    position: relative;
    text-align: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .peers-filter-cancel {
      position: absolute;
      left: 20px;
      top: 26px;
      cursor: pointer;
    }
  }
  .pair-modal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .peer {
      &:hover {
        background: #f2f5fb;
        cursor: pointer;
      }
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1rem;
      label {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        &:hover {
          cursor: pointer;
        }
      }
      input {
        width: fit-content;
      }
    }
  }
  .peers-footer {
    background-color: white;
    text-align: center;
    padding: 20px !important;
    box-shadow: 0 0 20px 0 #00000014;
    width: 100%;
    .simple-button {
      width: 100%;
      padding: 10px;
      background-color: #042c5c;
    }
  }
}
