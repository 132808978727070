@import '../../../../variables';

.sector {
  .subheader {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.sector-content {
  table {
    th {
      &:nth-child(1) {
        text-align: left;
        padding-left: 10px;

        .th-wrapper {
          justify-content: flex-start;
        }
      }
    }

    tr {
      position: relative;
      td {
        &:nth-child(1) {
          text-align: left;
          padding-left: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }

  &.is-desktop {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    .lists {
      display: flex;
      width: 72%;
      flex-wrap: wrap;
      justify-content: space-between;

      .list {
        width: 49%;
      }
    }

    .imported-notes {
      width: 25%;
    }
  }

  &.is-mobile {
    min-width: 100% !important;
    overflow-x: scroll;
    background-color: inherit;
  }
}

.indices-prices,
.pairs,
.indices-chart,
.imported-notes {
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 10px;
  }
}

.pairs {
  background-color: #fff;
  padding-bottom: 12px;

  tr td {
    font-weight: 800;
  }

  &.no-list {
    padding-bottom: 20px;
  }

  &:last-child {
    padding-bottom: 40px;
  }

  &-legend {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.23px;
    text-align: center;
    color: $color-grey-dark;
  }

  &.no-list {
    .pairs-legend {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.23px;
      text-align: center;
      color: $color-grey-dark;
    }
  }
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  thead {
    th {
      font-size: 10px;
      font-weight: 400;
      color: $color-grey-dark;
      padding-bottom: 3px;
      text-transform: uppercase;
      text-align: right;

      .th-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;

        .arrow {
          position: relative;
          top: 1px;
          margin-left: 1px;
        }
      }

      &:first-child {
        text-align: left;
        padding-left: 10px;

        .th-wrapper {
          justify-content: flex-start;
        }
      }

      &:last-child {
        padding-right: 10px;
        padding-left: 5px;
      }

      &.selected {
        font-weight: bold;
        color: $color-blue-dark;
      }
    }
  }

  .price-cell {
    padding-right: 10px;
  }

  .fixed {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 2;
    padding-right: 10px;
  }

  .headerFixed {
    background-color: white;
  }

  tbody {
    color: $color-blue-dark;

    tr {
      background-color: $color-light-blue;
      margin-bottom: 10px;
      padding: 10px;

      &.emitter-highlight {
        td {
          border: 1px solid #122b5c;
          border-right-width: 0;
          border-left-width: 0;
          &:nth-child(1) {
            border-left-width: 1px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            border-right-width: 1px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    td {
      font-size: 12px;
      font-weight: 600;
      padding: 10px 0;
      text-align: right;

      &:first-child {
        text-align: left;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 5px;
      }

      .me {
        font-weight: bold;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding-right: 12px;
        padding-left: 10px;
      }

      &.sorted {
        font-weight: 600;
      }
    }
  }
}

.style_legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
