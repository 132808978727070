@import "../../../variables.scss";

.daily-report {
  position: relative;
  top: -180px;

  &.desktop {
    top: 0;
    .app-container {
      top: -130px;
    }
  }
}

.report__page.mobile {
  img {
    margin: 2rem 0rem;
  }
  .data__container {
    display: flex;
    flex-direction: column;
    .no-data {
      padding: 0rem 1.5rem 1.5rem;
    }
  }
}

.report__page {
  .daypicker__container {
    background-color: #fff;
    text-align: center;
    padding: 4px;
    width: fit-content;
    margin: 0 auto;
    border-radius: 6px;
    margin-bottom: 10px;
    .DayPicker {
      font-size: 0.8rem;
    }
    .DayPickerInput {
      position: relative;
      display: inline-block;
      .DayPickerInput-Overlay {
        left: 30px;
      }
    }
    svg {
      vertical-align: middle;
      margin-right: 14px;
    }
    input {
      padding: 0;
      color: var(--color-black-light);
      border-bottom: none;
      font-size: 12px;
      width: fit-content;
    }
    .arrow-down {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      top: 10px;
      right: 7px;
      border-top: 5px solid var(--color-brand-blue);
    }
  }
  .tables__container {
    div {
      background-color: transparent !important;
    }
    tbody tr:last-child td {
      border-bottom: none;
    }
    .broker__container {
      display: flex;
      flex-direction: column;
      div {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .analysis__container {
    margin-top: 30px;
    .analysis__title {
      font-size: 30px;
      font-weight: bold;
      color: $color-blue-dark;
    }
    .analysis__detail {
      padding: 16px 20px;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: 20px;
      &.has-background {
        background-color: $color-light-blue;
      }
      &-content {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        color: $color-black-light;
        margin-top: 20px;
        strong {
          font-weight: 600;
        }
      }
      hr {
        margin-top: 1rem;
      }
      h1 {
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0.55px;
        color: $color-blue-dark;
      }
    }

    hr {
      width: 20pt;
      height: 2pt;
      background-color: var(--color-black-dark);
      border: 0;
      margin: 0;
    }
  }

  .agenda__container {
    margin-top: 20px;
    h4 {
      font-size: 30px;
      font-weight: bold;
      color: $color-blue-dark;
      margin-bottom: 20px;
    }
    &-event {
      padding-top: 10px;
      padding-bottom: 10px;
      &.even {
        background-color: $color-light-blue;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
      }

      .name {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: $color-blue-dark;
      }
      div {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .date {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.3px;
          color: $color-blue-dark;
        }
        .data {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.3px;
          color: $color-blue-dark;
        }
      }
      hr {
        margin-top: 10px;
        margin-bottom: 10px;
        color: var(--color-text-blue-light);
      }
    }
    &-updated {
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.23px;
      text-align: center;
      color: $color-grey-dark;
      margin-top: 20px;
    }
  }
  .stockchart {
    .filter-container,
    #chart-multiline-stock-bars {
      display: none;
    }
  }
}

.report__page.desktop {
  margin: 2.5rem 0;

  .DayPicker {
    font-size: 1rem;
  }
  .data__container {
    display: flex;
    justify-content: space-between;
    &-right__column {
      width: 50%;
    }
    .no-data {
      width: 50%;
      padding: 0rem 1.5rem 1.5rem;
    }
    .data__container-right__column {
      & > div {
        margin: 0 0 50px 0;
      }
    }
  }

  .analysis__container {
    width: 45%;
  }

  .quotes__container {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-end;

    .price-info {
      .price {
        background-color: $color-light-blue;
        margin-right: 20px;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        &__name {
          flex-basis: 50%;
          min-width: 200px;
        }

        &__price {
          flex-basis: 30%;
          font-weight: 500;
        }

        &__variation {
          display: none;
        }

        &__session {
          flex-basis: 30%;
          order: 4;
          max-width: inherit;
        }
      }
    }
  }
  .indices__item {
    background-color: $color-light-blue;
  }
  .values__container {
    width: 60% !important;
  }

  .stock-screenshot__container {
    img {
      margin: 1.75rem 1.25rem 1.25rem 1.25rem;
      width: -webkit-fill-available;
    }
  }
}

.economic-statistics {
  margin-top: 30px;
  .main-title {
    font-size: 30px;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 30px;
  }
  .zone-title {
    font-size: 22px;
    letter-spacing: 0.55px;
    color: $color-blue-dark;
    font-weight: 300;
  }

  .zone__container {
    &.even {
      background-color: white;
      padding-bottom: 30px;
    }
    &.odd {
      background-color: $color-light-blue;
      padding: 0 20px;
      padding-bottom: 30px;
      padding-top: 30px;
      margin-left: -20px;
      margin-right: -20px;
    }

    .country__container {
      padding-top: 20px;
      .header__container {
        font-size: 14px;
        font-style: italic;
        color: var(--color-black-dark);
        display: flex;
        justify-content: space-between;
      }
      hr {
        height: 1px;
        background-color: var(--color-grey-dark);
        border: 0;
      }
      .values__container {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 20px 0px 8px;
        &-detail {
          display: flex;
          flex-direction: column;
          .title {
            color: var(--color-grey-dark);
            font-size: 12px;
          }
          .value {
            font-size: 14px;
            font-weight: bold;
            color: $color-blue-dark;
          }
          &:first-child .value {
            font-weight: bold;
          }
        }
      }
    }
  }
  .statistics__container-updated {
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.23px;
    text-align: center;
    color: $color-grey-dark;
    margin-top: 20px;
  }
}
