@import "../../variables";
.statistics {
  position: relative;
  top: -180px;
  &.sector {
    top: -60px;
  }
  &.valuation {
    top: -160px;
  }
  &.desktop {
    top: 0;
    .app-container {
      top: -90px;
    }
    &.sector {
      .app-container {
        top: -40px;
      }
    }
  }
}
.subheader {
  display: flex;
  gap: 4px;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 13px;

  &__element {
    width: 150px;
    text-align: center;
    color: white;
    background-color: $color-brand-light-blue;
    border-radius: 6px;
    padding: 5px 10px;
    letter-spacing: 0.11px;
    font-size: 13px;
    font-weight: 400;
    &--active {
      font-weight: bold;
      background-color: white;
      color: $color-brand-light-blue;
    }
  }

  &.desktop {
    z-index: 1;
    //max-width: 500px;
    margin: 0 auto;
    .subheader__element {
      width: 150px;
      text-align: center;
      color: white;
      background-color: $color-brand-light-blue;
      border-radius: 6px;
      padding: 5px 10px;
      letter-spacing: 0.11px;
      font-size: 13px;
      font-weight: 400;
      margin-left: 20px;

      &--active {
        font-weight: bold;
        background-color: white;
        color: $color-brand-light-blue;
      }
    }
  }
}

.price-info.desktop {
  .price {
    width: 100%;
  }
  .infos {
    width: 100%;
    align-items: center;
    &__item {
      margin-top: 20px;
      flex-wrap: wrap;
      width: 33%;
      h5 {
        margin-top: 0;
      }
    }
  }
}

.tables__container {
  margin-top: 40px;
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  .indices-prices {
    margin-bottom: 32px;
    background-color: #fff;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.price-cumlulated-updated {
  background: $color-light-blue;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.23px;
  text-align: center;
  color: $color-grey-dark;
}

.price-info-stock.desktop {
  display: flex;
  margin-bottom: 20px;

  & > div {
    width: 50%;
  }

  .stockchart,
  .stockchart-container {
    width: 100%;
    margin-bottom: 0;
  }
}
