.loader {
  display: flex;
  align-items: center;

  .loader-svg {
    animation: rotate 1s ease-in-out infinite;
    transform-box: fill-box;
    transform-origin: center;
  }

  .loader-text {
    margin-left: 10px;
  }
}

#loader-portal {
  .portal__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    z-index: 999999;
  }
  .portal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .7;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
