@import "../../../../variables";

.app-container-wrapper.statistics {
  &.valuations {
    .app-container {
      position: relative;
      top: 0 !important;
      padding: 20px 20px 60px 20px !important;
      .subheader {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -40px;
      }
    }
  }
}

.valuation-content {
  .valuation-table {
    width: 50%;
  }
}
