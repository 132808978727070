@import '../../variables';

.quotevalue {
  font-size: 12px;
  font-weight: 800;
  display: inline-flex;
  align-items: center;

  .quote-arrow {
    &.up {
      path {
        fill: $color-positive-1;
      }
    }
    &.down {
      path {
        fill: $color-negative-1;
      }
    }
  }
}
