.login__input-container {
  &.cgv__checkbox {
    display: flex;
    flex-direction: row;
    input[type='checkbox'] {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 0;
      vertical-align: middle;
      margin-right: 8px;
    }
    input:not(:placeholder-shown) + span,
    .login__input-container input:focus + span {
      position: relative;
      bottom: inherit;
      font-size: 1rem;
      width: auto;
      display: inline-block;
    }
    a {
      text-decoration: underline;
    }
  }
}

.app-container__cgv {
  .result__error {
    font-size: 0.9rem;
    margin-bottom: 3rem;
    color: var(--color-negative-5);
  }
  .result__ok {
    font-size: 0.9rem;
    margin-bottom: 3rem;
    color: var(--color-positive-4);
  }
  .isLoading {
    margin-bottom: 3rem;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
