@import "../../../../variables";

.infos {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-top: 20px;
  
  &__item {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h5 {
      margin-bottom: 4px;
      color: #77869e;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #77869e;
    }

    .content {
      color: $color-blue-dark;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      &.bold {
        font-weight: bold;
      }
    }
  }
}
