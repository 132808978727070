@import "../../variables";

.rotate-chart {
  transform: rotate(-90deg);
}
.opacity {
  opacity: 0;
}

.fullscreen-chart {
  &.desktop {
    .filter-container {
      padding: 25px 25px 0 100px;
    }
    .stockchart {
      margin-left: 0;
    }
    .stockchart__legend {
      position: relative;
      padding-right: 40px;
    }
    .loader {
      position: fixed;
      top: 50%;
      left: 50%;
    }
    .mkt-footer {
      padding: 0px 20px;
      max-width: 600px;
      margin: 0 auto;
      width: 100%;
    }
  }
  &.mobile {
    .filter-container {
      position: fixed;
      z-index: 1;
      top: 40px;
      .button-opener {
        margin-left: 10px;
      }
    }
    .mkt-footer {
      padding-top: 10px;
    }
  }
  .close-fullscreen {
    top: 10px;
    left: 10px;
  }
  .fullscreen-chart-content {
    .stockchart.rotate-chart {
      transform: rotate(-90deg);
      display: block !important;
      #chart-multiline-stock {
        position: relative;
      }
      .stockchart__legend {
        padding-top: 40px;
      }
      .custom-modal {
        width: 100vh;
        .custom-modal-content {
          width: 100vh;
          max-width: unset;
        }
        .custom-modal-shadow {
          width: 100vh;
        }
      }
    }
  }
}

.stockchart {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  margin-right: -20px;

  .mkt-footer {
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__not-ready {
    background-color: $color-light-blue;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__legend {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light-blue;
    padding: 20px 20px 10px 20px;
    .legend-enlarge {
      background-color: $color-white;
      padding: 5px 5px 0px 5px;
      border-radius: 6px;
      border: none;
      cursor: pointer;
    }

    &-btn {
      border-radius: 6px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.12px;
      margin-right: 10px;
      color: #77869e;
      padding: 6px 8px;
      outline: none;
      cursor: pointer;

      &.selected {
        background-color: $color-medium-blue;
        color: $color-blue-dark;
        font-weight: bold;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: $color-blue-dark;
  }

  &.volumeschart {
    align-items: center;
    .stockchart__legend {
      display: block;
      background-color: white;
      padding-left: 0;
      padding-bottom: 20px;
    }
  }

  .button-opener {
    color: #042c5c;
    background: #f2f5fb;
    border: 1.9px #042c5c solid;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 30px;
    font-size: 12px;

    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }
  .button-opener-date {
    color: #042c5c;
    background: #f2f5fb;
    border: 1.9px #042c5c solid;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 30px;
    font-size: 12px;
    padding-inline: 5px;
    position: relative;
    cursor: pointer;
  }

  .chart-title {
    font-size: 15px;
    color: grey;
    background-color: #f2f5fa;
    padding: 0 20px;
  }

  .chart-header {
    background: #f2f5fb;
    padding: 10px 20px 0px 20px;
    font-size: 13px;

    .chart-header-top {
      display: flex;
      align-items: center;

      & > div {
        margin-right: 5px;

        &:nth-child(1) {
          color: black;
          font-weight: bold;
          font-size: 20px;
        }

        &:nth-child(2) {
          display: flex;
          color: maroon;
          background-color: #f5e6e6;
          padding: 2px 5px;
          border-radius: 5px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          color: maroon;
          align-items: center;
        }
      }
    }

    .chart-header-bottom {
      color: grey;
      font-size: 10px;
    }
  }

  .chart-footer-tabla {
    background: #f2f5fb;
    font-size: 11px;
    padding: 10px 20px 0px 20px;

    .chart-footer-tabla-cell {
      display: flex;
      align-items: center;
      border-bottom: 1px solid lightgrey;

      & > div {
        flex: 1;
        margin: 10px 0px 10px 10px;

        &:nth-child(1) {
          padding-left: 5px;
          margin-left: 0;
        }

        &:nth-child(2) {
          font-weight: bold;
          flex: none;
        }

        &:nth-child(3) {
          color: maroon;
          flex: none;
        }

        &:nth-child(4) {
          display: flex;
          flex: none;
          color: maroon;
          background-color: #f5e6e6;
          padding: 2px 5px;
          border-radius: 5px;
        }

        &:last-child {
          border-bottom: none;
          margin: 0;
          flex: none;
        }
      }
    }
  }
}

.stockchart-linechart {
  padding-top: 10px;

  &__bar {
    padding-top: 0;
  }

  width: 100%;
}

#my-bar-chart-volumes {
  background-color: $color-light-blue;
  padding-top: 10px;
  max-width: 500px;
  animation: chartjs-render-animation 0.001s;
  padding-right: 20px;
}

#chart-multiline-stock {
  background-color: $color-light-blue;
  display: table;
  width: 100%;
}

#chart-multiline-stock-loader {
  background-color: $color-light-blue;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#chart-multiline-stock-bars {
  display: table;
  width: 100%;
  background-color: white;
  position: relative;
  top: -14px;
  &:before {
    content: "";
    width: 100%;
    border: 1px solid $color-brand-light-blue;
    position: absolute;
    top: 3px;
  }
}

.reset-filter-button {
  text-align: right;
  color: #042c5c;
  border: none;
  font-size: 15px;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.icon-calendar-img {
  height: 20px;
  width: 20px;
}

.icon-filter {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}

.icon-filter-cross {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.filter-container {
  background-color: $color-light-blue;
  display: flex;
  justify-content: space-between;
  padding-inline: 10px;
  padding-top: 10px;
}

.text-filter {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
}

.date-picker {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 30px;
  .container-date {
    max-width: 100px !important;
  }
}

.calendar {
  width: 100%;
  color: black;
  padding: 0;
  margin-top: 7px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  border-bottom: none;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
}

.icon-check {
  margin-top: 8px;
  width: 15px;
  height: 15px;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.mkt-footer {
  .mkt-footer__row {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    font-size: 13px;

    span {
      color: $color-positive-1;
      margin-right: 5px;
    }

    span:nth-child(2) {
      color: #042c5c !important;
      font-weight: normal;
      width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
    }
    span:nth-child(3) {
      font-weight: 700;
    }
    span:nth-child(4) {
      font-weight: 700;
      width: 50px;
      text-align: right;
    }
    .mkt-footer__arrow {
      background: #e2f2eb;
      font-weight: bold;
      border-radius: 5px;
      padding: 2px;
    }
    &.negative {
      span {
        color: $color-negative-1;
      }
      .mkt-footer__arrow {
        background: #f6e6e7;
      }
    }
  }
  .mkt-footer__rectangle {
    width: 8px;
    height: 14px;
    border-radius: 2px;
    margin-right: 10px;
  }
}

.date-picker {
  .DayPicker-Months {
    background: white;
    z-index: 1;
    position: relative;
    select {
      margin: 5px;
      padding: 5px;
      cursor: pointer;
    }
  }
  &.mobile {
    .date-picker__close-button {
      position: fixed;
      top: 20px;
      left: 10px;
    }
    .overlayWrapper {
      position: fixed;
      top: 0;
      background-color: white;
      width: 100%;
      left: 0;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }
    &.fullscreen {
      .overlayWrapper {
        height: 100vw;
        width: 100vh;
        margin-top: 20px;
        z-index: 100;
        .date-picker__close-button {
          position: fixed;
          top: 40px;
          left: 20px;
        }
      }
    }
  }
  &.desktop {
    .date-picker__close-button {
      display: none;
    }
    .overlayWrapper {
      position: absolute;
      left: 0;
      background-color: white;
      width: auto;
      top: 30px;
      z-index: 1;
    }
  }
}

// FOR THE NEW DATE PICKER

.input-calendar {
  max-width: 100px;
}
.datepicker-popup {
  position: relative;
  .close-datepicker-button {
    position: absolute;
    z-index: 3;
    cursor: pointer;
    font-size: 17px;
    top: 18px;
    left: 5px;
  }
  .DayPicker-wrapper {
    position: absolute;
    z-index: 2;
  }
}

.date-picker {
  .DayPicker-Months {
    select {
      border: none;
    }
  }
  .close-datepicker-button {
    font-size: 25px;
    position: absolute;
  }
  &.mobile {
    .datepicker-popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 11;
      .DayPicker-Months {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: fixed !important;
      }
    }
    &.fullscreen {
      .datepicker-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        .DayPicker-Months {
          transform: translate(-50%, -50%);
          top: 35%;
          left: 50%;
          position: fixed !important;
        }
      }
      .close-datepicker-button {
        top: 40px;
        left: 20px;
      }
    }
  }
}

.date-picker-error {
  color: red;
  font-size: 12px;
  padding-left: 10px;
  position: absolute;
  top: 40px;
}
