@import '../../variables';

.search__content {
    padding-bottom: 100px;
    .search-input {
        margin: 20px 0;
        border: 1px $color-brand-light-blue;
        border-radius: 4px;
    }
    button {
        width: 100%;
        margin: 5px 0;
    }
}

.search-results {
    color: $color-blue-dark;
    border-bottom: 1px solid $color-blue-dark;
    font-weight: 600;
    margin: 20px 0 10px;
}

.search-filters {
    display: flex;
    padding: 10px 0;
    & > div:nth-child(1) {
        position: relative;
        &:before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -8px;
            background: $color-blue-dark;
        }
    }
    .search-filter {
        margin-right: 15px;
        background-color: white;
        color: $color-blue-dark;
        border: 0;
        padding: 5px 16px;
        border-radius: 23px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
        font-size: 14px;
        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
        &.selected {
            background-color: $color-blue-dark;
            color: white;
        }
    }
}

.search-input {
    position: relative;
    svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto;
    }
    input {
        padding: 1rem 0.8rem 1rem 2.5rem;
        border: 1px solid $color-brand-light-blue;
        border-radius: 6px;
        color: black;
    }
}


.card-search {
    position: relative;
    padding: 8px 20px 12px 45px;
    border-radius: 12px;
    font-size: 12px;
    background-color: $color-light-blue;
    margin: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    &:hover {
        cursor: pointer;
        outline: 1px solid $color-blue-dark;
    }
    div {
        color: $color-blue-dark;
        padding: 2px 0;
        &:nth-child(2) {
            display: flex;
            justify-content: space-between;
        }
    }
    svg {
        position: absolute;
        left: 13px;
    }
    .type, .action {
        font-weight: bold;
        text-transform: capitalize;
    }
    .company {
        font-weight: bold;
        text-transform: uppercase;
    }  
    .title {
        color: $color-blue-dark;
        text-transform: capitalize;
    }
}