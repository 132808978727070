@import '../../variables.scss';

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background-color: var(--color-white);
  box-shadow: 0 -0.5px 0 0 #eaeaea;
  padding-top: 0.4rem;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 10;

  a {
    flex: 1;
    color: $color-blue-dark;
    text-align: center;
    font-size: 0.7rem;
    padding-bottom: 0.3rem;

    &.active {
      font-weight: 600;

      svg {
        background: $color-blue-dark;
        padding: 2px;
        border-radius: 50%;
      }
    }

    &.disabled {
      color: $color-grey-dark;
      pointer-events: none;
      svg {
        opacity: 0.5;
      }
    }

    div {
      font-size: 0;
      color: var(--color-brand-blue);
    }
  }
}


.red-circle {
  background-color: red;
  height: 10px;
  width: 10px;
  position: relative;
  left: 70%;
  top: 8px;
  border-radius: 10px;
  margin-top: -10px;
}