@import "./variables.scss";

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:root,
[data-theme="default"] {
  // variables not defined by zeplin
  --header-height: #{$header-height};
  --env-safe-area-top: env(safe-area-inset-top);
  --app-container-header-height: #{$app-container-header-height};
  --footer-height: #{$footer-height};
  --container-desktop-width: #{$container-desktop-width};
  --container-desktop-padding: #{$container-desktop-padding};
  --desktop-background: #{$desktop-background};
  --footer-links: #{$footer-links};
  --subheader-bg: #{$subheader-bg};
  --subheader-bg-active: #{$subheader-bg-active};
  --color-grey-dates: #{$color-grey-dates};
  --color-blue-indices-bg: #{$color-blue-indices-bg};

  // variables defined by zeplin
  --color-negative-1: #{$color-negative-1};
  --color-negative-2: #{$color-negative-1};
  --color-negative-3: #{$color-negative-1};
  --color-negative-4: #{$color-negative-1};
  --color-negative-5: #{$color-negative-1};
  --color-positive-1: #{$color-positive-1};
  --color-positive-2: #{$color-positive-1};
  --color-positive-3: #{$color-positive-1};
  --color-positive-4: #{$color-positive-1};
  --color-red: #{$color-red};
  --color-blue: #{$color-blue};
  --color-blue-dark: #{$color-blue-dark};
  --color-brand-blue: #{$color-brand-blue};
  --color-text-blue-light: #{$color-text-blue-light};
  --color-brand-indigo-25: #{$color-brand-indigo-25};
  --color-yellow: #{$color-yellow};
  --color-gold: #{$color-gold};
  --color-gold-lighter: #{$color-gold-lighter};
  --color-brand-gold-25: #{$color-brand-gold-25};
  --color-white: #{$color-white};
  --color-white-dark: #{$color-white-dark};
  --color-black-dark: #{$color-black-dark};
  --color-black-light: #{$color-black-light};
  --color-grey: #{$color-grey};
  --color-grey-dark: #{$color-grey-dark};
  --color-grey-darker: #{$color-grey-darker};
}

html {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  min-height: 100vh;
  touch-action: manipulation;
}

.ptr--top {
  touch-action: manipulation !important;
}

#root {
  @media only screen and (max-device-width: 812px) and (orientation: landscape) {
    background-color: var(--color-brand-blue);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    & > div {
      background-color: #fff;
    }

    .app-container {
      padding-bottom: 0;
    }

    footer {
      position: sticky;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

button[type="submit"],
button[type="submit"],
button.default {
  width: 100%;
  border: solid 1px var(--color-white);
  background-color: var(--color-brand-blue);
  padding: 0.5rem 3rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: 0;
  transition: opacity 0.3s;
  align-items: center;
  margin: 0 auto;
  border-radius: 6px;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.none {
  display: none;
}

.btn__rm-styles {
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
  outline: 0;
  color: var(--color-text-blue-light);
}

label {
  span {
    display: block;
    margin-bottom: 0.5rem;
  }
}

input {
  padding: 0.8rem 0.8rem 1rem;
  width: 100%;
  font-size: 0.9rem;
  color: var(--color-white);
  border-width: 0px;
  border-bottom: 1px solid var(--color-text-blue-light);
  background-color: transparent;
  /* &:-internal-autofill-selected {
            background-color: #fff !important;
          } */
}

.success {
  color: var(--color-positive-4);
}

.error {
  color: var(--color-negative-3);
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.action__bullets {
  margin-bottom: 0.6rem;

  span {
    font-size: 0.8rem;
    border-radius: 16px;
    color: white;
    background-color: $color-grey-dark;
    padding: 3px 10px;
    font-weight: 600;
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }

    &.news,
    &.breaking_news {
      background-color: $color-grey-dark;
      color: $color-black-light;
    }

    &.reco_change {
      background-color: var(--color-brand-indigo-25);
      color: $color-black-light;
    }

    &.rating,
    &.reco_tp {
      background-color: var(--color-brand-indigo-25);
      color: $color-black-light;
    }

    &.pre_opening {
      background-color: var(--color-red);
      color: $color-black-light;
    }

    &.price_movement {
      background-color: #fee6a1;
      color: $color-black-light;
    }

    &.broker_interest,
    &.brokers_interests {
      background-color: #fee6a1;
      color: $color-black-light;
    }

    &.sales_traders_comments {
      background-color: #bbffe5;
      color: $color-black-light;
    }

    &.peer_report {
      background-color: #fee6a1;
      color: $color-black-light;
    }

    &.brokers_comments {
      background-color: #bbffe5;
      color: $color-black-light;
    }
  }
}

.no-results {
  font-size: large;
  font-weight: bold;

  &__message {
    font-size: large;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
    padding: 30px;
  }
}

.login-logo {
  position: absolute;
  top: 0;
  right: 0;
}

.center-page__loader .loader {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.no-data {
  font-size: 1rem;
  font-weight: bold;
}

.nooverflow {
  overflow-y: unset;
}

.version-control-modal {
  .custom-modal {
    .custom-modal-content {
      text-align: center;
      label {
        width: 100%;
        display: block;
        background: lightgrey;
        color: black;
        padding: 5px;
      }
      section {
        padding: 20px;
      }
      & > div {
        padding-bottom: 20px;
      }
      a {
        color: blue;
      }
      button {
        margin: 10px;
        background: var(--color-brand-blue);
        border-radius: 6px;
        border: none;
        padding: 10px 20px;
        color: white;
        cursor: pointer;
      }
    }
  }
}
.main-no-verified {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-image: url('../public/video_poster.jpg');
  background-size: cover;
}

.biometric-loading {
  height: 100vh;
  width: 100vh;
  background-color: #001E41;
  z-index: 9999999999;
}