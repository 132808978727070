.user__container {
  padding: 2rem 1.2rem 1rem;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &.background {
    background-color: var(--color-brand-blue);
    position: absolute;
    top: 0;
    padding-bottom: 53px;
    height: 100%;
    width: 100%;
    padding-top: 30px;
  }
  .back__container {
    align-self: flex-start;
  }
  .logo__container {
    align-self: center;
    margin-bottom: 73px;
    svg {
      width: 45px;
      height: 100%;
    }
  }
  .user {
    color: var(--color-white);
    font-style: normal;
    font-size: 1rem;
    margin-bottom: 1rem;
    .user__name {
      font-size: 1.375rem;
      margin-bottom: 0.6rem;
    }
    .user__company {
      font-style: italic;
      color: var(--color-text-blue-light);
      margin-bottom: 2rem;
    }
    .user__phone {
      margin-top: 1rem;
    }
    hr {
      width: 217px;
      height: 1px;
      opacity: 0.51;
      border: solid 1px #013c84;
      margin-bottom: 2rem;
    }
  }
  .logout__container {
    margin: 10rem 2rem 0rem;
  }
}
