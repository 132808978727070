.custom-modal {
    z-index: 100000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow-y: hidden;


    .custom-modal-shadow {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        opacity: 0.5;
        background-color: black;
    }

    .custom-modal-content {
        position: fixed;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: white;
        box-shadow: 0px 0px 4px 0px grey;
        border-radius: 5px;
        z-index: 0;
        max-width: 600px;
        width: 90%;
        max-height: 80%;
        height: auto;
        overflow: auto;
    }
}


.app-container-wrapper {
    &.mobile {
        .custom-modal {
            .custom-modal-shadow {
                opacity: 1;
                background-color: #042c5c;
                text-align: center;
                position: relative;
                padding-top: 16px;
            }
            .custom-modal-content {
                position: fixed;
                transform: unset;
                box-shadow: unset;
                top: 61px;
                left: 0;
                width: 100%;
                min-height: calc(100vh - 61px);
                border-top-left-radius: 14px;
                border-top-right-radius: 14px;
            }
        }
    }
}