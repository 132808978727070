@import '../../variables';

.simple-button {
    background-color: $color-blue-dark;
    color: white;
    border: 0;
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}