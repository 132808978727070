@import '../../variables';

.short {
  .short__container {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    flex-wrap: wrap;

    &-short {
      @media (max-width: $lg) {
        margin-top: 2rem;
      }
    }

    &-sellers {
      width: 100%;
      @media (max-width: $lg) {
        margin-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
  .short-enlarge-button {
    color: #042c5c;
    background: transparent;
    border: none;
    font-size: 12px;
    padding: 0;
    &:hover {
      color: grey ;
      cursor: pointer;
    }
  }
  .last-updated {
    margin-top: 1rem;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.23px;
    display: block;
    text-align: left;
    margin-top: 0;
    color: #828286;
  }

  h1,
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $color-blue-dark;
    text-align: left;
    @media (max-width: $sm) {
      font-size: 27px;
      padding-bottom: 20px;
    }
  }
  .stockchart {
    &-linechart {
      border-top: 0;
    }
  }
  &__lending {
    .stockchart {
      background-color: $color-light-blue;
    }
  }
  canvas {
    background-color: #f2f5fb;
    margin-bottom: 0.5rem;
  }

  .short__lending, .short__stock {
    .stockchart {
      margin-right: 20px;
      margin-left: -20px;
      background: #f2f5fb;
    }
  }

  &.desktop {
    .short__lending, .short__stock {
      .stockchart {
        margin-right: 0;
        margin-left: 0;
        background: #f2f5fb;
      }
    }
    .short__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: flex-start;

      &-sellers,
      &-lending,
      &-short {
        width: 33%;
        display: table;
      }
      &-lending {
        margin-right: 20px;
      }
      &-short {
        margin-right: 40px;
      }
      &-sellers {
        margin-right: 0;
      }
    }
    .short__lending {
      h1 {
        text-align: left;
        padding-bottom: 20px;
      }
    }
    .short__stock {
      h2 {
        text-align: left;
        padding-bottom: 20px;
      }
    }
    .short__sellers {
      table {
        margin-left: -20px;
        margin-right: -20px;
        max-width: 100%;
        tbody {
          td {
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
              font-size: 12px;
              font-weight: 500;
            }
            .broker__container {
              font-weight: 600;
              font-size: 12px;
              & > div {
                font-weight: normal;
              }
            }
          }
        }
        thead {
          th {
            font-weight: normal;
            &.selected {
              font-weight: 600;
            }
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
          }
        }
      }
    }
    h1,
    h2 {
      padding: 0;
    }
  }
  .chart-footer {
    color: $color-blue-dark;
    font-size: 12px;
    padding: 5px;
    & > div {
      padding: 5px;
      display: flex;
      align-items: center;
      & > div:nth-child(1) {
        width: 5px;
        height: 14px;
        margin-right: 5px;
        padding-left: 8px;
        border-radius: 2px;
      }
      span:nth-child(3) {
        flex: 1;
        text-align: right;
        font-weight: bold;
        padding-right: 2px;
      }
    } 
  }
}
