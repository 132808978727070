.fullscreen-chart {
    position: fixed;
    min-width: 100% !important;
    min-height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    .fullscreen-chart-content {
        z-index: 2;
        position: absolute !important;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        display: flex;
        justify-items: center;
    }
    .fullscreen-chart-shadow {
        position: fixed;
        width: 100% !important;
        height: 100%;
        top: 0;
        left: 0;
        background: white;
    }
    .fullscreen-chart-button {
        z-index: 1;
        color: black;
        position: relative;
        margin-left: auto;
        display: block;
        padding-right: 20px;
    }
    .close-fullscreen {
        color: black;
        z-index: 3;
        position: fixed;
        right: 0;
        padding: 10px;
        background: white;
        border-radius: 50%;
        max-width: 52px;
        min-width: 52px;
    }
}