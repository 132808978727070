@import "../../../../variables";

.stock-filter {
  &.mobile {
    .container-header-filter-feature {
      flex-direction: column;
      padding: 0;
      .reset-filter-button {
        background: white;
        border-bottom: 1px solid #f2f5fa;
      }
      & > div {
        padding: 10px;
        width: 100%;
        text-align: center;
        background-color: #f2f5fb;
      }
    }
  }
  .stock-filter-picker {
    color: black;
  }

  .stock-filter-main-title {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
  }

  .stock-filter-title {
    font-weight: bold;
  }

  .stock-filter-item {
    padding: 10px;
  }

  .stock-filter-ticker-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 13px;
    padding: 5px;

    &.no-result {
      &:hover {
        cursor: none;
        background-color: transparent;
        font-weight: normal;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #f2f5fb;
      color: #042c5c;
      font-weight: bold;
    }

    .exchange {
      color: $color-grey-dark;
      font-size: 12px;
    }
  }

  .container-header {
    .container-header-filter {
      background: #1c4a81;
      padding: 20px;
      color: white;
      position: relative;
      text-align: center;
      .filter-close {
        position: absolute;
        left: 30px;
        top: 26px;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
    .container-header-filter-feature {
      padding: 20px;
      display: flex;
      flex-direction: row;
      height: auto;
      justify-content: space-between;
      align-items: center;
      color: #243967;
      background-color: white;
      box-shadow: 0 0 20px 0 #00000014;
    }
  }
  .container-filter {
    color: #243967;
    padding: 20px 0 0;
    background: #8080800a;
    & > div {
      padding: 0 20px;
    }
    .stock-filter__toolbar {
      background-color: white;
      text-align: center;
      padding: 20px !important;
      box-shadow: 0 0 20px 0 #00000014;
      width: 100%;
      .simple-button {
        width: 100%;
        padding: 10px;
        background-color: #042c5c;
      }
    }
  }
  .txt-max-reached {
    color: red;
    margin-bottom: 10px;
    text-align: right;
  }
}

.checkbox {
  width: auto;
  margin-right: 10px;
}


