.flashs__content {
  padding: 1.2rem 0 4rem 0;
  min-height: 100%;
  flex-shrink: 0;
  &.flashs__desktop {
    display: flex;
    justify-content: space-between;
    .flashs__byDate {
      margin-right: 2.2rem;
      width: calc(40% - 2.2rem);
      .flashs{
        position: relative;
        overflow: auto;
        max-height: calc(100vh - 15rem);
      }
    }
    .app-container-wrapper {
      background-color: #fff;
      min-height: auto;
      width: 60%;
      position: relative;
      overflow: auto;
      max-height: calc(100vh - 15rem);
      .app-container {
        min-height: auto;
        padding-bottom: 0;
      }
    }
  }
}
