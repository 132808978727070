.footer-desktop {
  padding: 0.5rem 2rem;
  text-align: left;
  position: relative;
  display: block;
  box-shadow: none;
  font-family: Arial;
  font-size: 13px;
  color: var(--color-black-light);
  a {
    font-family: Arial;
    font-size: 13px;
    color: var(--color-black-light);
    text-decoration: underline;
  }
}
